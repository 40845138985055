<template>
  <section class="shang-hu">
    <p class="na-li">立享花在哪里</p>
    <div class="shang-hu-list">
      <div v-for="(item,index) in businessArr" :key="index" :class="['shang-hu-item flex-center',{'white':!item.img,'ty10':index===businessIndex}]" @mouseenter="enter(index)" @mouseleave="leave()">
        <template v-if="item.img">
          <img :src="item.img" class="list-img">
          <div :class="index===businessIndex?'list-blue':'list-grey'" />
          <div class="shang-hu-content">
            <img :src="item.logo" class="list-logo">
            <span>{{ item.title }}</span>
          </div>
        </template>
        <template v-else>
          <span v-html="item.title" />
        </template>
      </div>
    </div>
    <div class="business-logo">
      <div v-for="(item,index) in businessLogo" :key="index" class="business-logo-item">
        <img :src="item.logo">
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from '@vue/reactivity'
const businessArr = [
  // { img: require('../images/img_a.png'), title: '家居家具', logo: require('../images/icon_jiaju.png') },
  { img: require('../images/img_b.png'), title: '口腔健康', logo: require('../images/icon_kouqiang.png') },
  { img: require('../images/img_c.png'), title: '植发护理', logo: require('../images/icon_zhifa.png') },
  { img: require('../images/img_d.png'), title: '产后修复', logo: require('../images/icon_chanhou.png') },
  { img: require('../images/img_e.png'), title: '婚庆婚礼', logo: require('../images/icon_hunqing.png') },
  { img: '', title: '更多行业<br/>敬请期待' }
]// 商家图
const businessLogo = []// 商家logo
for (let i = 1; i < 16; i++) {
  businessLogo.push({ logo: require(`../images/logo_${i}.png`) })
}
const businessIndex = ref(null)// 鼠标移动到的图片
const enter = (index) => {
  businessIndex.value = index
}
const leave = () => {
  businessIndex.value = null
}
</script>

<style lang='less' scoped>
.shang-hu{
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #999999;
  margin:161px auto 0;
  .flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .na-li{
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #191919;
    text-align: center;
  }
  .shang-hu-list{
    width:1200px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:55px auto 0;
    cursor:pointer;
    .list-img{
      position: absolute;
      z-index:1;
    }
    .list-grey{
      width: 174px;
      height: 218px;
      position: absolute;
      z-index:2;
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 2px 20px 0px rgba(199, 209, 221, 0.5);
    }
    .list-blue{
      width: 174px;
      height: 218px;
      position: absolute;
      z-index:2;
      background: rgba(68, 129, 253, 0.9);
      box-shadow: 0px 2px 20px 0px rgba(199, 209, 221, 0.5);
    }
    .ty10{
      transition: all 0.3s;
      transform: translateY(-10px);
    }
    .shang-hu-item{
      width: 174px;
      height: 218px;
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0px 2px 20px 0px rgba(199, 209, 221, 0.5);
      position:relative;
      .shang-hu-content{
        position: relative;
        display:flex;
        flex-direction: column;
        align-items:center;
        z-index:4;
        >span{
          font-size: 16px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #FFFFFF;
          padding-top:8px;
        }
      }
    }
  }
  .business-logo{
    margin:63px auto 0;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .business-logo-item{
      display: block;
      width:205px;
      height:62px;
      margin-top:29px;
    }
  }
  .white{
    background: #FFFFFF !important;
  }
}
</style>
