<template>
  <div class="number">
    <div v-if="title" class="title" v-html="title" />
    <div class="business">
      <div v-for="(item,index) in businessArr" :key="index" class="item">
        <img :src="item.logo" class="item-logo">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from '@vue/runtime-core'
const businessArr = [
  { logo: require(`../images/icon_001.png`), title: '3000+', content: '合作商户' },
  { logo: require(`../images/icon_002.png`), title: '10000+', content: '服务用户' },
  { logo: require(`../images/icon_003.png`), title: '50000+', content: '交易订单' }
]// 商家
defineProps(
  {
    title: {
      type: String,
      default: ''
    }
  }
)
</script>
<style lang='less' scoped>
.number{
  width: 100%;
  background: #4481FD;
  .title{
    padding:131px 0 5px;
    text-align: center;
    font-size: 40px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
  }
  .business{
    margin:31px auto 5px;
    width:1004px;
    display:flex;
    justify-content:space-between;
    .item{
      margin-top:102px;
      text-align: center;
      .item-logo{
        width:100px;
        height:100px;
      }
      .item-title{
        padding-top:31px;
        font-size: 50px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: #FFFFFF;
      }
      .item-content{
        padding-top:6px;
        margin-bottom:85px;
        font-size: 30px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>
